import React from 'react';
import {Helmet} from "react-helmet";
import EIB from '../assets/svg/logo/ecolesbilingues_logo.svg'
import {Link} from "gatsby";
const NotFound = () => {

    return (
        <>
            <div className='container-custom page d-flex page404'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>EIB Écoles Internationales Bilingues</title>
                    <meta name="description"
                          content="Nos écoles privées internationales accueillent des enfants de toutes nationalités en proposant un réel bilinguisme 50% anglais, 50% français dès l'âge de 2 ans."/>
                </Helmet>
                <div className='content w-100 fadeInEarth'>
                    <div className='text text-center'>
                        <div className='eib-404'>
                            <img src={EIB} alt='Eib Nice' />
                        </div>
                        <div className='content-title text-center w-100 mb-0'>
                            <h1>Erreur 404</h1>
                        </div>
                        <p>Oops ! La page demandée est introuvable.</p>
                        <Link to='/'>
                            <button className='btn btn-primary'>
                                Revenir sur la page d'accueil
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;